<template>
  <b-card
    class="col-12"
    title="Responses">
    <input type="search" placeholder="Search responses..." @keyup="filterResponses" v-model="searchValue">   
    <br>
    <br>
    <b-table :items="filteredResponses" :fields="fields">
      <template #cell(admin)="data">
        <div>
          <img src="@/assets/admin.png" alt="" v-if="data.item.admin">
        </div>
      </template>
      <template #cell(delete)="data">
        <div class="status-icon inline-icon" v-b-modal.modal-update>
          <router-link :to="'/compile/' + data.item.userId + '/' + data.item.projectId">
            <b-icon icon="eye"></b-icon>
          </router-link>

          <b-icon icon="file-earmark" @click="exportResponses(data.item.id)"></b-icon>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
export default {
  data: function () {
    return {
      responses: [],
      filteredResponses: [],
      fields: [
        {
          key: "admin",
          label:"Sent to Admin"
        },
        {
          key: 'userName',
          label: 'Name',
          sortable: true
        },
        {
          key: 'projectName',
          label: 'Project',
          sortable: true
        },
        {
          key: 'delete',
          label: 'Actions',
          sortable: true
        }
      ],
      searchValue: ""
    }
  },

  mounted () {
    this.loadResponses()
  },

  methods: {
    filterResponses() {
       this.filteredResponses = this.responses.filter((item) => {
         return item.userName.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1
         || item.projectName.toLowerCase().indexOf(this.searchValue.toLowerCase()) !== -1       
       }
       )
    },
    async loadResponses() {
      try {
        let resp = await this.$firestore.collection('responses').get()
        const firstValue = this.getSnapshotRecords(resp, false) 
        this.responses = firstValue
        this.filteredResponses = firstValue

      } catch (e) {
        console.log(e)
      }
    },

    projectName (id) {
      let returnName = ''

      this.projects.forEach((item) => {
        if (item.value == id) {
          returnName = item.text
        }
      })

      return returnName
    },

    exportResponses (id) {
      window.open(this.$params.baseURL + '/excel/' + id)
    }
  }
}
</script>

<style>
.error-text {
  color: red;
  text-align: center;
}
img {
  width:25px;
  height: auto;
}
</style>

